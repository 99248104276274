import Body from '../components/Body';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import AppContext from '../components/AppContext';

export default function HomePage({ isLoggedIn = false }) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setSignInFailed(false);
    appContext.setSignUpFailed(false);
    appContext.setPasswordResetFailed(false);
    appContext.setAuthenticatedPasswordResetFailed(false);
  }, []);

  return (
    <Body>
      <Row>
        <Col>
          <Card bg="light" border="primary" text="primary">
            <Card.Body>
              <Card.Title>Classes</Card.Title>
              <Card.Text>
                Create class lists and assign helpful attributes to each student.
              </Card.Text>
              <Button
                variant="outline-primary"
                onClick={() => (isLoggedIn ? navigate('/classes') : navigate('/signin'))}
              >
                {"Let's go!"}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="light" border="warning" text="warning">
            <Card.Body>
              <Card.Title>Orders</Card.Title>
              <Card.Text>Generate and edit optimized line orders from your classes.</Card.Text>
              <Button
                variant="outline-warning"
                onClick={() => (isLoggedIn ? navigate('/orders') : navigate('/signin'))}
              >
                {"Let's go!"}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="light" border="info" text="info">
            <Card.Body>
              <Card.Title>Layouts</Card.Title>
              <Card.Text>Create custom room layouts for the desks in your classroom.</Card.Text>
              <Button
                variant="outline-info"
                onClick={() => (isLoggedIn ? navigate('/layouts') : navigate('/signin'))}
              >
                {"Let's go!"}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="light" border="danger" text="danger">
            <Card.Body>
              <Card.Title>Charts</Card.Title>
              <Card.Text>
                Generate and edit optimized seating charts from your classes and layouts.
              </Card.Text>
              <Button
                variant="outline-danger"
                onClick={() => (isLoggedIn ? navigate('/charts') : navigate('/signin'))}
              >
                {"Let's go!"}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col className="m-auto">
          <Image fluid className="pt-5 d-block mx-auto" src={toolbox} alt="Toolbox logo" />
        </Col>
      </Row> */}
    </Body>
  );
}
