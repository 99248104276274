import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AppContext from '../components/AppContext';

export default function ResetPasswordForm({ isLoggedIn, email, token }) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const schema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required')
  });

  return (
    <Formik
      initialValues={{ password: '', passwordConfirmation: '' }}
      onSubmit={(values, { setSubmitting }) => {
        if (isLoggedIn) {
          appContext.setAuthenticatedPasswordResetFailed(false);
          setTimeout(() => {
            fetch('/api/auth/reset_password_auth', {
              method: 'POST',
              headers: {
                Authorization: 'Basic ' + appContext.token,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                password: values.password
              })
            })
              .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
              })
              .then((data) => {
                navigate('/home');
              })
              .catch((error) => {
                console.log(error);
                appContext.setAuthenticatedPasswordResetFailed(true);
                navigate('/user');
              });
            setSubmitting(false);
          }, 100);
        } else {
          appContext.setPasswordResetFailed(false);
          setTimeout(() => {
            fetch('/api/auth/reset_password', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email,
                token,
                password: values.password
              })
            })
              .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
              })
              .then((data) => {
                navigate('/user');
              })
              .catch((error) => {
                console.log(error);
                appContext.setPasswordResetFailed(true);
                navigate('/reset-password-request');
              });
            setSubmitting(false);
          }, 100);
        }
      }}
      validationSchema={schema}
    >
      {(formik, isSubmitting) => (
        <Form>
          <div className="form-group pt-2">
            <label htmlFor="password">New Password</label>
            <Field
              name="password"
              type="password"
              className={
                formik.touched.password && formik.errors.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="form-group pt-2">
            <label htmlFor="passwordConfirmation">New Password Confirmation</label>
            <Field
              name="passwordConfirmation"
              type="password"
              className={
                formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
              <div className="invalid-feedback">{formik.errors.passwordConfirmation}</div>
            ) : null}
          </div>
          <div className="form-group pt-3">
            <button type="submit" className="btn btn-outline-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Please wait...' : 'Reset Password'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
