import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './mintybootstrap.min.css';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // NOTE: Disabling this to fix an issue with react-beautiful-dnd and this react version
  //       but there might be a better fix here:
  //          https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1213532725
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
