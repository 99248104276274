import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import AppContext from '../components/AppContext';
import Body from '../components/Body';

export default function SignOutPage() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const signOut = () => {
    appContext.reset();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    signOut();
  }, []);

  return (
    <Body>
      <p>Signing out...</p>
    </Body>
  );
}
