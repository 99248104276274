import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';

export default function AddStudentForm({ addStudentFunction }) {
  const schema = Yup.object().shape({
    name: Yup.string().required('Student name is required')
  });

  return (
    <Formik
      initialValues={{ name: '' }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        addStudentFunction(values.name);
        resetForm();
        setSubmitting(false);
      }}
      validationSchema={schema}
    >
      {(formik, isSubmitting) => (
        <Form>
          <Row>
            <Col md={7}>
              <div className="form-group">
                <Field
                  name="name"
                  type="name"
                  className={
                    formik.touched.name && formik.errors.name
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                ) : null}
              </div>
            </Col>
            <Col md={5}>
              <div className="form-group">
                <button type="submit" className="btn btn-outline-success" disabled={isSubmitting}>
                  {isSubmitting ? 'Please wait...' : 'Add Student'}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
