import Body from '../components/Body';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import AppContext from '../components/AppContext';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';

export default function UserPage() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [deleteModalActive, setDeleteModalActive] = useState(false);

  const handleDeleteModalClose = () => setDeleteModalActive(false);

  const handleDeleteAccount = (name) => {
    fetch('/api/user', {
      method: 'DELETE',
      headers: {
        Authorization: 'Basic ' + appContext.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        appContext.reset();
      })
      .catch((error) => {
        console.log(error);
        navigate('/user');
      });
  };

  return (
    <Body>
      <Row className="justify-content-center pt-1">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={4}>
          {!appContext.userData.google ? (
            <Button variant="outline-primary" onClick={() => navigate('/reset-password')}>
              {'Reset Password'}
            </Button>
          ) : (
            'Signed in with Google'
          )}
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
      {appContext.authenticatedPasswordResetFailed ? (
        <Row className="justify-content-center pt-4">
          <Col xs={1} md={3} lg={4}></Col>
          <Col xs={10} md={6} lg={4}>
            <Alert
              variant="danger"
              onClose={() => appContext.setAuthenticatedPasswordResetFailed(false)}
              dismissible
            >
              <p>The request to reset your password failed. Please try again!</p>
            </Alert>
          </Col>
          <Col xs={1} md={3} lg={4}></Col>
        </Row>
      ) : null}
      <Row className="justify-content-center">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={4}>
          <hr />
          <Button variant="outline-danger" onClick={() => navigate('/signout')}>
            {'Sign Out'}
          </Button>
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={4}>
          <hr />
          <Button variant="danger" onClick={() => setDeleteModalActive(true)}>
            {'Delete Account'}
          </Button>
        </Col>
        <Modal
          show={deleteModalActive}
          onHide={handleDeleteModalClose}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This will delete EVERYTHING associated with your account. This includes all the classes,
            orders, layouts, and charts you&apos;ve worked so hard to create!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleDeleteModalClose}>
              Wait, no! Don&apos;t do that!
            </Button>
            <Button variant="danger" onClick={handleDeleteAccount}>
              Yes, I&apos;m sure. Delete everything!
            </Button>
          </Modal.Footer>
        </Modal>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
    </Body>
  );
}
