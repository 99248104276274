import Body from '../components/Body';
import { useState, useContext, useRef } from 'react';
import AppContext from '../components/AppContext';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Creatable from 'react-select/creatable';
import Selecto from 'react-selecto';
import Moveable from 'react-moveable';
import { SelectTheme, SelectLabel, Colors, Scales } from '../components/Constants';
import { useMouseHovered } from 'react-use';
import { gridToPixels, pixelsToGrid, getColorFromPerformanceLevel } from '../components/Utilities';

const MIN_SCALE = 0;
const DEFAULT_SCALE = 3;
const MAX_SCALE = 3;
const SNAP_DEGREE_GAP = 15;
const DEFAULT_SEAT_PERFORMANCE_LEVEL = 2;

export default function LayoutsPage() {
  const appContext = useContext(AppContext);

  const [selectedLayoutIndex, setSelectedLayoutIndex] = useState(0);

  const [targets, setTargets] = useState([]);
  const [frameMap, setFrameMap] = useState(() => new Map());
  const moveableRef = useRef(null);
  const selectoRef = useRef(null);

  const dragBoxRef = useRef(null);
  const layoutBoxRef = useRef(null);

  const [selected, setSelected] = useState([]);

  const [editMode, setEditMode] = useState(true);

  const addLayout = (name) => {
    const newLayoutData = {
      value: name,
      label: name,
      sub_id: appContext.userData.next_layout_sub_id,
      name,
      scale: DEFAULT_SCALE,
      inverted: false,
      seats: [],
      next_seat_id: 0
    };
    const newLayoutsData = appContext.layoutsData.concat(newLayoutData);
    setSelectedLayoutIndex(appContext.layoutsData.length);
    appContext.updateLayoutsData(newLayoutsData);
    setSelected([]);
    setTargets([]);
    setFrameMap(() => new Map());

    const newUserData = { ...appContext.userData };
    newUserData.next_layout_sub_id = appContext.userData.next_layout_sub_id + 1;
    appContext.updateUserData(newUserData);
  };

  const handleLayoutChange = (selectedOption) => {
    for (let i = 0; i < appContext.layoutsData.length; i++) {
      if (selectedOption.sub_id === appContext.layoutsData[i].sub_id) {
        setSelected([]);
        setTargets([]);
        setFrameMap(() => new Map());
        setSelectedLayoutIndex(i);
      }
    }
  };

  const addSeat = (newX, newY, newP) => {
    const radius = Scales[appContext.layoutsData[selectedLayoutIndex].scale];
    const newXSpot = pixelsToGrid(newX, radius);
    const newYSpot = pixelsToGrid(newY, radius);
    const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
      if (idx === selectedLayoutIndex) {
        const newIndex = currentLayout.next_seat_id;
        const initialRotation = currentLayout.inverted ? 180 : 0;
        return {
          ...currentLayout,
          seats: [
            ...currentLayout.seats,
            {
              id: newIndex,
              x: newXSpot,
              y: newYSpot,
              r: initialRotation,
              performance: DEFAULT_SEAT_PERFORMANCE_LEVEL
            }
          ],
          next_seat_id: newIndex + 1
        };
      } else {
        return currentLayout;
      }
    });
    appContext.updateLayoutsData(newLayoutsData);
  };

  const deleteSeats = () => {
    if (selected.length > 0) {
      const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
        if (idx === selectedLayoutIndex) {
          const newSeatsData = currentLayout.seats.filter(
            (el) =>
              currentLayout.seats.indexOf(el) >= 0 &&
              selected.indexOf(currentLayout.seats.indexOf(el)) < 0
          );
          return {
            ...currentLayout,
            seats: newSeatsData
          };
        } else {
          return currentLayout;
        }
      });
      appContext.updateLayoutsData(newLayoutsData);
      setSelected([]);
      setTargets([]);
      setFrameMap(() => new Map());
    }
  };

  const setSelectedSeatsPerformanceLevel = (level) => {
    if (selected.length > 0) {
      const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
        if (idx === selectedLayoutIndex) {
          const newSeatsData = currentLayout.seats.map((currentSeat, idy) => {
            if (selected.indexOf(idy) >= 0) {
              // This is the case where the seat is in the selected list, replace the performance level
              return {
                id: currentSeat.id,
                x: currentSeat.x,
                y: currentSeat.y,
                r: currentSeat.r,
                performance: level
              };
            } else {
              return currentSeat;
            }
          });
          return {
            ...currentLayout,
            seats: newSeatsData
          };
        } else {
          return currentLayout;
        }
      });
      appContext.updateLayoutsData(newLayoutsData);
    }
  };

  const editSeat = (seatIndex, newX, newY, newR) => {
    const radius = Scales[appContext.layoutsData[selectedLayoutIndex].scale];
    const newXSpot = pixelsToGrid(newX, radius);
    const newYSpot = pixelsToGrid(newY, radius);
    const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
      if (idx === selectedLayoutIndex) {
        const newSeatsData = currentLayout.seats.map((currentSeat, idy) => {
          if (idy === seatIndex) {
            return {
              id: currentSeat.id,
              x: newXSpot,
              y: newYSpot,
              r: newR,
              performance: currentSeat.performance
            };
          } else {
            return currentSeat;
          }
        });
        return {
          ...currentLayout,
          seats: newSeatsData
        };
      } else {
        return currentLayout;
      }
    });
    appContext.updateLayoutsData(newLayoutsData);
  };

  const editSeats = (seatIndexes, newXs, newYs, newRs) => {
    const radius = Scales[appContext.layoutsData[selectedLayoutIndex].scale];
    const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
      if (idx === selectedLayoutIndex) {
        const newSeatsData = currentLayout.seats.map((currentSeat, idy) => {
          const loc = seatIndexes.indexOf(idy);
          const newXSpot = pixelsToGrid(newXs[loc], radius);
          const newYSpot = pixelsToGrid(newYs[loc], radius);
          if (loc !== -1) {
            return {
              id: currentSeat.id,
              x: newXSpot,
              y: newYSpot,
              r: newRs[loc],
              performance: currentSeat.performance
            };
          } else {
            return currentSeat;
          }
        });
        return {
          ...currentLayout,
          seats: newSeatsData
        };
      } else {
        return currentLayout;
      }
    });
    appContext.updateLayoutsData(newLayoutsData);
  };

  const deleteLayout = () => {
    const newLayoutsData = appContext.layoutsData.filter(
      (el) => el.sub_id !== appContext.layoutsData[selectedLayoutIndex].sub_id
    );
    appContext.updateLayoutsData(newLayoutsData);
    setSelectedLayoutIndex(0);
    setSelected([]);
    setTargets([]);
    setFrameMap(() => new Map());
  };

  const changeScale = (increase) => {
    if (appContext.layoutsData.length > selectedLayoutIndex) {
      setSelected([]);
      setTargets([]);
      setFrameMap(() => new Map());

      let newScale = appContext.layoutsData[selectedLayoutIndex].scale;
      let newSeats = appContext.layoutsData[selectedLayoutIndex].seats;
      if (increase && appContext.layoutsData[selectedLayoutIndex].scale + 1 <= MAX_SCALE) {
        newScale = appContext.layoutsData[selectedLayoutIndex].scale + 1;

        // If we are increasing the scale, we also need to make sure all seats are moved into the allowed area
        const maxGridX = 800 / (Scales[newScale] / 5) - 10;
        const maxGridY = 600 / (Scales[newScale] / 5) - 10;

        newSeats = appContext.layoutsData[selectedLayoutIndex].seats.map((currentSeat, ids) => {
          let newX = currentSeat.x;
          let newY = currentSeat.y;
          if (currentSeat.x > maxGridX) {
            newX = maxGridX;
          }
          if (currentSeat.y > maxGridY) {
            newY = maxGridY;
          }
          return {
            id: currentSeat.id,
            x: newX,
            y: newY,
            r: currentSeat.r,
            performance: currentSeat.performance
          };
        });
      } else if (!increase && appContext.layoutsData[selectedLayoutIndex].scale - 1 >= MIN_SCALE) {
        newScale = appContext.layoutsData[selectedLayoutIndex].scale - 1;
      }
      const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
        if (idx === selectedLayoutIndex) {
          return {
            ...currentLayout,
            seats: newSeats,
            scale: newScale
          };
        } else {
          return currentLayout;
        }
      });
      appContext.updateLayoutsData(newLayoutsData);
    }
  };

  const setInvertedFunction = (invertedInteger) => {
    let invertedBool = true;
    if (invertedInteger === 0) {
      invertedBool = false;
    }

    const newLayoutsData = appContext.layoutsData.map((currentLayout, idx) => {
      if (idx === selectedLayoutIndex) {
        return {
          ...currentLayout,
          inverted: invertedBool
        };
      } else {
        return currentLayout;
      }
    });
    appContext.updateLayoutsData(newLayoutsData);
  };

  const handleClick = () => {
    if (editMode) {
      // Do nothing, for now
    } else {
      addSeat(hoverSpotX, hoverSpotY);
    }
  };

  let layoutEdit = null;
  const snapTargets = [];
  let currentLayoutIsInverted = false;
  let radius = Scales[DEFAULT_SCALE];
  let numSpots = 0;
  if (appContext.layoutsData.length > selectedLayoutIndex) {
    currentLayoutIsInverted = appContext.layoutsData[selectedLayoutIndex].inverted;
    layoutEdit = appContext.layoutsData[selectedLayoutIndex].seats.map((s, i) => {
      snapTargets.push('#layoutEditorSeat_' + i.toString());
      radius = Scales[appContext.layoutsData[selectedLayoutIndex].scale];
      numSpots = appContext.layoutsData[selectedLayoutIndex].seats.length;
      const xLoc = gridToPixels(s.x, radius);
      const yLoc = gridToPixels(s.y, radius);
      const color = getColorFromPerformanceLevel(s.performance);
      return (
        <svg
          className="target cube SeatingChartDesk"
          key={'layoutEditorSeat_' + i.toString()}
          alt="Desk"
          draggable="false"
          id={'layoutEditorSeat_' + i.toString()}
          style={{
            transform: `translateX(${xLoc}px) translateY(${yLoc}px) rotate(0deg)`,
            position: 'absolute'
          }}
          xmlns="http://www.w3.org/2000/svg"
          width={(radius * 2).toString()}
          height={(radius * 2).toString()}
        >
          <circle
            cx={radius.toString()}
            cy={radius.toString()}
            r={(radius - radius / 20).toString()}
            stroke={color}
            strokeWidth={(radius / 10).toString()}
            fill={color}
            fillOpacity="0.25"
          />
        </svg>
      );
    });
  }

  const { elX, elY } = useMouseHovered(layoutBoxRef, {
    bound: true,
    whenHovered: false
  });

  const hoverSpotX =
    Math.min(
      Math.max(Math.round((elX - radius) / (radius / 5)) * (radius / 5), 0),
      800 - radius * 2
    ) + 1;
  const hoverSpotY =
    Math.min(
      Math.max(Math.round((elY - radius) / (radius / 5)) * (radius / 5), 0),
      600 - radius * 2
    ) + 1;

  return (
    <Body>
      <Row>
        <Col md={6}>
          <label style={SelectLabel} id="aria-label-layout" htmlFor="aria-example-input-layout">
            Select a layout or type a name to create a new one
          </label>
          {appContext.layoutsData.length > 0 ? (
            <Creatable
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.layoutsData}
              onChange={handleLayoutChange}
              onCreateOption={addLayout}
              value={appContext.layoutsData[selectedLayoutIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Creatable
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.layoutsData}
              onChange={handleLayoutChange}
              onCreateOption={addLayout}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={2}>
          {appContext.layoutsData.length > 0 ? (
            <div>
              <Row className="justify-content-center">
                <Button variant="danger" onClick={() => deleteLayout()}>
                  Delete Layout
                </Button>
              </Row>
              <Row className="justify-content-center pt-2">
                <p>{'Number of spots: ' + numSpots}</p>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </Col>
        <Col md={4}>
          {appContext.layoutsData.length > 0 ? (
            <div>
              <Row className="justify-content-center">
                <ButtonGroup>
                  <Button onClick={() => changeScale(false)} variant="outline-secondary">
                    Scale -
                  </Button>
                  <Button onClick={() => changeScale(true)} variant="outline-secondary">
                    Scale +
                  </Button>
                </ButtonGroup>
              </Row>
              <Row className="justify-content-center pt-1">
                <ToggleButtonGroup
                  type="radio"
                  value={currentLayoutIsInverted ? 1 : 0}
                  name={'inverted_options_name'}
                  id={'inverted_options_id'}
                  key={'inverted_options_key'}
                  onChange={(val) => setInvertedFunction(val)}
                >
                  <ToggleButton
                    id={'inverted_option_not_inverted_id'}
                    key={'inverted_option_not_inverted_key'}
                    value={0}
                    variant="outline-primary"
                  >
                    {'top = front'}
                  </ToggleButton>
                  <ToggleButton
                    id={'inverted_option_inverted_id'}
                    key={'inverted_option_inverted_key'}
                    value={1}
                    variant="outline-primary"
                  >
                    {'bottom = front'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <div className="dragBox" id="dragBox" ref={dragBoxRef}>
        <div
          className="layoutBox elements selecto-area"
          id="layoutBox"
          ref={layoutBoxRef}
          onClick={handleClick}
        >
          <div>
            {editMode ? (
              <div>
                <Moveable
                  ref={moveableRef}
                  target={targets}
                  draggable={true}
                  rotatable={false}
                  snappable={true}
                  bounds={{ left: 0, top: 0, right: 800, bottom: 600 }} // Size of layoutBox
                  snapGap={false}
                  snapDirections={{
                    top: true,
                    left: true,
                    bottom: true,
                    right: true,
                    center: false,
                    middle: false
                  }}
                  snapGridHeight={radius / 5}
                  snapGridWidth={radius / 5}
                  snapThreshold={radius / 5}
                  onClickGroup={(e) => {
                    selectoRef.current.clickTarget(e.inputEvent, e.inputTarget);
                  }}
                  onDragStart={(e) => {
                    const target = e.target;

                    const xStart = target.style.transform.indexOf('translateX(');
                    const xEnd = target.style.transform.indexOf('px)');
                    const initialX = Number(target.style.transform.substring(xStart + 11, xEnd));

                    const yStart = target.style.transform.indexOf('translateY(');
                    const yEnd = target.style.transform.indexOf('px) r');
                    const initialY = Number(target.style.transform.substring(yStart + 11, yEnd));

                    const rotateStart = target.style.transform.indexOf('rotate(');
                    const rotateEnd = target.style.transform.indexOf('deg)');
                    const initialRotate = Number(
                      target.style.transform.substring(rotateStart + 7, rotateEnd)
                    );

                    if (!frameMap.has(target)) {
                      frameMap.set(target, {
                        translate: [initialX, initialY],
                        rotate: initialRotate
                      });
                    }
                    const frame = frameMap.get(target);

                    e.set(frame.translate);
                  }}
                  onDrag={(e) => {
                    const target = e.target;
                    const frame = frameMap.get(target);

                    frame.translate = e.beforeTranslate;
                    target.style.transform = `translateX(${frame.translate[0]}px) translateY(${frame.translate[1]}px) rotate(${frame.rotate}deg)`;
                  }}
                  onDragEnd={(e) => {
                    const target = e.target;
                    const frame = frameMap.get(target);

                    const seatIndex = Number(target.id.replace(/\D/g, ''));

                    editSeat(seatIndex, frame.translate[0], frame.translate[1], frame.rotate);
                  }}
                  onDragGroupStart={(e) => {
                    e.events.forEach((ev) => {
                      const target = ev.target;

                      const xStart = target.style.transform.indexOf('translateX(');
                      const xEnd = target.style.transform.indexOf('px)');
                      const initialX = Number(target.style.transform.substring(xStart + 11, xEnd));

                      const yStart = target.style.transform.indexOf('translateY(');
                      const yEnd = target.style.transform.indexOf('px) r');
                      const initialY = Number(target.style.transform.substring(yStart + 11, yEnd));

                      const rotateStart = target.style.transform.indexOf('rotate(');
                      const rotateEnd = target.style.transform.indexOf('deg)');
                      const initialRotate = Number(
                        target.style.transform.substring(rotateStart + 7, rotateEnd)
                      );

                      if (!frameMap.has(target)) {
                        frameMap.set(target, {
                          translate: [initialX, initialY],
                          rotate: initialRotate
                        });
                      }
                      const frame = frameMap.get(target);

                      ev.set(frame.translate);
                    });
                  }}
                  onDragGroup={(e) => {
                    e.events.forEach((ev) => {
                      const target = ev.target;
                      const frame = frameMap.get(target);

                      frame.translate = ev.beforeTranslate;
                      target.style.transform = `translateX(${frame.translate[0]}px) translateY(${frame.translate[1]}px) rotate(${frame.rotate}deg)`;
                    });
                  }}
                  onDragGroupEnd={(e) => {
                    const seatIndexes = [];
                    const xs = [];
                    const ys = [];
                    const rs = [];

                    e.events.forEach((ev) => {
                      const target = ev.target;
                      const frame = frameMap.get(target);

                      seatIndexes.push(Number(target.id.replace(/\D/g, '')));
                      xs.push(frame.translate[0]);
                      ys.push(frame.translate[1]);
                      rs.push(frame.rotate);
                    });

                    editSeats(seatIndexes, xs, ys, rs);
                  }}
                  onRotateStart={(e) => {
                    const target = e.target;

                    const xStart = target.style.transform.indexOf('translateX(');
                    const xEnd = target.style.transform.indexOf('px)');
                    const initialX = Number(target.style.transform.substring(xStart + 11, xEnd));

                    const yStart = target.style.transform.indexOf('translateY(');
                    const yEnd = target.style.transform.indexOf('px) r');
                    const initialY = Number(target.style.transform.substring(yStart + 11, yEnd));

                    const rotateStart = target.style.transform.indexOf('rotate(');
                    const rotateEnd = target.style.transform.indexOf('deg)');
                    const initialRotate = Number(
                      target.style.transform.substring(rotateStart + 7, rotateEnd)
                    );

                    if (!frameMap.has(target)) {
                      frameMap.set(target, {
                        translate: [initialX, initialY],
                        rotate: initialRotate
                      });
                    }
                    const frame = frameMap.get(target);

                    e.set(frame.rotate);
                  }}
                  onRotate={(e) => {
                    const target = e.target;
                    const frame = frameMap.get(target);

                    let newRotation = e.rotation % 360;
                    const a = parseInt(newRotation / SNAP_DEGREE_GAP, 10) * SNAP_DEGREE_GAP;
                    const b = a + SNAP_DEGREE_GAP;
                    newRotation = newRotation - a > b - newRotation ? b : a;

                    frame.rotate = newRotation;
                    target.style.transform = `translateX(${frame.translate[0]}px) translateY(${frame.translate[1]}px) rotate(${newRotation}deg)`;
                  }}
                  onRotateEnd={(e) => {
                    const target = e.target;
                    const frame = frameMap.get(target);

                    const seatIndex = Number(target.id.replace(/\D/g, ''));

                    editSeat(seatIndex, frame.translate[0], frame.translate[1], frame.rotate);
                  }}
                  onRotateGroupStart={(e) => {
                    e.events.forEach((ev) => {
                      const target = ev.target;

                      const xStart = target.style.transform.indexOf('translateX(');
                      const xEnd = target.style.transform.indexOf('px)');
                      const initialX = Number(target.style.transform.substring(xStart + 11, xEnd));

                      const yStart = target.style.transform.indexOf('translateY(');
                      const yEnd = target.style.transform.indexOf('px) r');
                      const initialY = Number(target.style.transform.substring(yStart + 11, yEnd));

                      const rotateStart = target.style.transform.indexOf('rotate(');
                      const rotateEnd = target.style.transform.indexOf('deg)');
                      const initialRotate = Number(
                        target.style.transform.substring(rotateStart + 7, rotateEnd)
                      );

                      if (!frameMap.has(target)) {
                        frameMap.set(target, {
                          translate: [initialX, initialY],
                          rotate: initialRotate
                        });
                      }
                      const frame = frameMap.get(target);

                      ev.dragStart.set([frame.translate[0], frame.translate[1]]);

                      ev.set(frame.rotate);
                    });
                  }}
                  onRotateGroup={(e) => {
                    e.events.forEach((ev) => {
                      const target = ev.target;
                      const frame = frameMap.get(target);

                      frame.translate[0] = ev.drag.beforeTranslate[0];
                      frame.translate[1] = ev.drag.beforeTranslate[1];
                      frame.rotate = ev.rotation;

                      target.style.transform = `translateX(${frame.translate[0]}px) translateY(${frame.translate[1]}px) rotate(${frame.rotate}deg)`;
                    });
                  }}
                  onRotateGroupEnd={(e) => {
                    const seatIndexes = [];
                    const xs = [];
                    const ys = [];
                    const rs = [];

                    e.events.forEach((ev) => {
                      const target = ev.target;
                      const frame = frameMap.get(target);

                      seatIndexes.push(Number(target.id.replace(/\D/g, '')));
                      xs.push(frame.translate[0]);
                      ys.push(frame.translate[1]);
                      rs.push(frame.rotate);
                    });

                    editSeats(seatIndexes, xs, ys, rs);
                  }}
                ></Moveable>
                <Selecto
                  ref={selectoRef}
                  dragContainer={'.elements'}
                  selectableTargets={['.selecto-area .cube']}
                  hitRate={0}
                  selectByClick={true}
                  selectFromInside={false}
                  clickBySelectEnd={false}
                  toggleContinueSelect={['shift']}
                  ratio={0}
                  onDragStart={(e) => {
                    const moveable = moveableRef.current;
                    const target = e.inputEvent.target;
                    if (
                      moveable.isMoveableElement(target) ||
                      targets.some((t) => t === target || t.contains(target))
                    ) {
                      e.stop();
                    }
                  }}
                  onSelectEnd={(e) => {
                    const moveable = moveableRef.current;
                    setTargets(e.selected);

                    const seatIndexes = [];
                    let i = 0;
                    for (i = 0; i < e.selected.length; i++) {
                      seatIndexes.push(Number(e.selected[i].id.replace(/\D/g, '')));
                    }
                    setSelected(seatIndexes);

                    if (e.isDragStart) {
                      e.inputEvent.preventDefault();
                      setTimeout(() => {
                        moveable.dragStart(e.inputEvent);
                      });
                    }
                  }}
                ></Selecto>
              </div>
            ) : (
              <div />
            )}
            <div className="empty elements">{layoutEdit}</div>
            {editMode ? (
              <div />
            ) : appContext.layoutsData.length > 0 ? (
              <svg
                alt="Desk"
                draggable="false"
                style={{
                  transform: `translateX(${hoverSpotX}px) translateY(${hoverSpotY}px) rotate(0deg)`,
                  position: 'absolute'
                }}
                xmlns="http://www.w3.org/2000/svg"
                width={(radius * 2).toString()}
                height={(radius * 2).toString()}
              >
                <circle
                  cx={radius.toString()}
                  cy={radius.toString()}
                  r={(radius - radius / 20).toString()}
                  stroke={Colors.dark}
                  strokeOpacity={'0.5'}
                  strokeWidth={(radius / 10).toString()}
                  fill={Colors.dark}
                  fillOpacity="0.1"
                />
              </svg>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="studentsBox">
          <Container fluid>
            {appContext.layoutsData.length > 0 ? (
              <div>
                <Row className="pt-3">
                  <Row>
                    <label style={SelectLabel}>Mode</label>
                  </Row>
                  <ToggleButtonGroup
                    type="radio"
                    value={editMode ? 1 : 0}
                    name={'mode_group_name'}
                    id={'mode_group_id'}
                    key={'mode_group_key'}
                    onChange={(val) => setEditMode(val)}
                  >
                    <ToggleButton
                      id={'edit_mode_id'}
                      key={'edit_mode_key'}
                      value={1}
                      variant="outline-primary"
                    >
                      {'EDIT'}
                    </ToggleButton>
                    <ToggleButton
                      id={'add_mode_id'}
                      key={'add_mode_key'}
                      value={0}
                      variant="outline-primary"
                    >
                      {'ADD'}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                <hr />
                {editMode ? (
                  <div>
                    <Row>
                      <label style={SelectLabel}>Performance</label>
                    </Row>
                    <Row className="justify-content-center">
                      <ButtonGroup>
                        <Button
                          variant="danger"
                          onClick={() => setSelectedSeatsPerformanceLevel(0)}
                        >
                          1
                        </Button>
                        <Button
                          variant="warning"
                          onClick={() => setSelectedSeatsPerformanceLevel(1)}
                        >
                          2
                        </Button>
                        <Button variant="dark" onClick={() => setSelectedSeatsPerformanceLevel(2)}>
                          3
                        </Button>
                        <Button variant="info" onClick={() => setSelectedSeatsPerformanceLevel(3)}>
                          4
                        </Button>
                        <Button
                          variant="success"
                          onClick={() => setSelectedSeatsPerformanceLevel(4)}
                        >
                          5
                        </Button>
                      </ButtonGroup>
                    </Row>
                    <hr />
                    <Row>
                      <ButtonGroup>
                        <Button variant="outline-danger" onClick={() => deleteSeats()}>
                          Delete Selected
                        </Button>
                      </ButtonGroup>
                    </Row>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <div />
            )}
          </Container>
        </div>
      </div>
    </Body>
  );
}

// TODO long-term:
// - Community feature for sharing layouts between users - rating system, filtering, etc.
// - Add support for GROUPS of spots, either options made by me or custom (with editor)
