import { Navbar, Nav, NavDropdown, Badge, Container, Image, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import AppContext from './AppContext';
import toolbox from '../assets/closed-toolbox.svg';
import Gravatar from 'react-gravatar';

export default function Header() {
  const appContext = useContext(AppContext);

  return (
    <Navbar bg="light" sticky="top" className="Header">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <Image
            src={toolbox}
            alt="Toolbox logo"
            width="32"
            height="32"
            className="d-inline-block align-top"
          />{' '}
          Classroom Toolbox
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {appContext.token && appContext.userData ? (
              <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item as={NavLink} to="classes">
                  Classes
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="orders">
                  Orders
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="layouts">
                  Layouts
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="charts">
                  Seating Charts
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
          </Nav>
          <Nav className="me-auto">
            {appContext.token && appContext.userData ? (
              <Navbar.Text className="ml-auto">
                {appContext.overallSaveState === 'UNSAVED' ? (
                  <div>
                    <Badge bg="danger">Unsaved changes (refresh to discard)</Badge>{' '}
                    <Badge bg="success">
                      <button
                        className="header-button"
                        onClick={() => {
                          appContext.saveAllData();
                        }}
                      >
                        Click to save
                      </button>
                    </Badge>
                  </div>
                ) : appContext.overallSaveState === 'SAVING' ? (
                  <Spinner animation="border" variant="primary" size="sm" />
                ) : appContext.overallSaveState === 'SAVED' ? (
                  <Badge bg="success">
                    <span>&#10003; Saved</span>
                  </Badge>
                ) : null}
              </Navbar.Text>
            ) : null}
          </Nav>
          <Nav>
            {!appContext.token || !appContext.userData ? (
              <></>
            ) : (
              <>
                <Nav.Link as={NavLink} to="user" className="ml-auto">
                  <Gravatar email={appContext.userData.email} size={32} default="identicon" />
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            {!appContext.token || !appContext.userData ? (
              <>
                <Nav.Link as={NavLink} to="signin" className="ml-auto">
                  Sign In
                </Nav.Link>
                <Nav.Link as={NavLink} to="signup" className="ml-auto">
                  Sign Up
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={NavLink} to="user" className="ml-auto">
                  {appContext.userData.username}
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
