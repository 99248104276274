import Body from '../components/Body';
import { useState, useContext } from 'react';
import AppContext from '../components/AppContext';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { Button, Badge, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SelectLabel, SelectTheme } from '../components/Constants';

export default function OrdersPage() {
  const [selectedClassIndex, setSelectedClassIndex] = useState(0);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0);
  const appContext = useContext(AppContext);

  const handleClassChange = (selectedOption) => {
    for (let i = 0; i < appContext.classesData.length; i++) {
      if (selectedOption.sub_id === appContext.classesData[i].sub_id) {
        setSelectedClassIndex(i);
        setSelectedOrderIndex(0);
      }
    }
  };

  const handleOrderChange = (selectedOption) => {
    for (let i = 0; i < appContext.classesData[selectedClassIndex].orders.length; i++) {
      if (selectedOption === appContext.classesData[selectedClassIndex].orders[i]) {
        setSelectedOrderIndex(i);
      }
    }
  };

  const addOrder = (name) => {
    const newClassesData = appContext.classesData.map((currentClass, idx) => {
      if (selectedClassIndex === idx) {
        const thisOrderId = currentClass.next_order_id;
        return {
          ...appContext.classesData[selectedClassIndex],
          next_order_id: thisOrderId + 1,
          orders: [
            ...appContext.classesData[selectedClassIndex].orders,
            { id: thisOrderId, value: name, label: name, student_ids: [] }
          ]
        };
      } else {
        return currentClass;
      }
    });
    appContext.updateClassesData(newClassesData);
    setSelectedOrderIndex(newClassesData[selectedClassIndex].orders.length - 1);
  };

  const deleteOrder = () => {
    const newClassesData = appContext.classesData.map((currentClass, idx) => {
      if (selectedClassIndex === idx) {
        const newOrdersData = currentClass.orders.filter(
          (el) => el.id !== appContext.classesData[selectedClassIndex].orders[selectedOrderIndex].id
        );
        return {
          ...appContext.classesData[selectedClassIndex],
          orders: newOrdersData
        };
      } else {
        return currentClass;
      }
    });
    appContext.updateClassesData(newClassesData);
  };

  const generateOrder = () => {
    if (
      appContext.classesData.length > selectedClassIndex &&
      appContext.classesData[selectedClassIndex].orders.length > selectedOrderIndex
    ) {
      fetch('/api/orders/generate', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ' + appContext.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          students: appContext.classesData[selectedClassIndex].students
        })
      })
        .then((response) => {
          if (response.status === 401) appContext.reset();
          else if (!response.ok) throw new Error(response.status);
          else return response.json();
        })
        .then((data) => {
          const newClassesData = appContext.classesData.map((currentClass, idx) => {
            if (selectedClassIndex === idx) {
              const newOrdersData = currentClass.orders.map((currentOrder, idy) => {
                if (selectedOrderIndex === idy) {
                  currentOrder.student_ids = data.order;
                  return currentOrder;
                } else {
                  return currentOrder;
                }
              });
              return {
                ...appContext.classesData[selectedClassIndex],
                orders: newOrdersData
              };
            } else {
              return currentClass;
            }
          });
          appContext.updateClassesData(newClassesData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      appContext.classesData[selectedClassIndex].orders[selectedOrderIndex].student_ids,
      result.source.index,
      result.destination.index
    );

    const newClassesData = appContext.classesData.map((currentClass, idx) => {
      if (selectedClassIndex === idx) {
        const newOrdersData = currentClass.orders.map((currentOrder, idy) => {
          if (selectedOrderIndex === idy) {
            currentOrder.student_ids = items;
            return currentOrder;
          } else {
            return currentOrder;
          }
        });
        return {
          ...appContext.classesData[selectedClassIndex],
          orders: newOrdersData
        };
      } else {
        return currentClass;
      }
    });
    appContext.updateClassesData(newClassesData);
  };

  var order = null;
  if (
    appContext.classesData.length > selectedClassIndex &&
    appContext.classesData[selectedClassIndex].orders.length > selectedOrderIndex
  ) {
    order = appContext.classesData[selectedClassIndex].orders[selectedOrderIndex].student_ids.map(
      (s, i) => {
        let name = '';

        for (let j = 0; j < appContext.classesData[selectedClassIndex].students.length; j++) {
          if (s === appContext.classesData[selectedClassIndex].students[j].id) {
            name = appContext.classesData[selectedClassIndex].students[j].name;
            break;
          }
        }

        if (name !== '') {
          return (
            <Draggable
              key={'order_object_' + i.toString()}
              draggableId={'order_object_' + i.toString()}
              index={i}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  // style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  <Badge bg="dark">{name}</Badge>
                </div>
              )}
            </Draggable>
          );
        } else {
          return null;
        }
      }
    );
  }

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: grid
  });

  return (
    <Body>
      <Row>
        <Col md={5}>
          <label style={SelectLabel} id="aria-label-class" htmlFor="aria-example-input-class">
            Select a class
          </label>
          {appContext.classesData.length > 0 ? (
            <Select
              aria-labelledby="aria-label-class"
              inputId="aria-example-input-class"
              options={appContext.classesData}
              onChange={handleClassChange}
              value={appContext.classesData[selectedClassIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Select
              aria-labelledby="aria-label-class"
              inputId="aria-example-input-class"
              options={appContext.classesData}
              onChange={handleClassChange}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={5}>
          <label style={SelectLabel} id="aria-label-class" htmlFor="aria-example-input-class">
            Select an order or type a name to create a new one
          </label>
          {appContext.classesData.length > 0 &&
          appContext.classesData[selectedClassIndex].orders.length > 0 ? (
            <Creatable
              aria-labelledby="aria-label-class"
              inputId="aria-example-input-class"
              options={appContext.classesData[selectedClassIndex].orders}
              onChange={handleOrderChange}
              onCreateOption={addOrder}
              value={appContext.classesData[selectedClassIndex].orders[selectedOrderIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Creatable
              aria-labelledby="aria-label-class"
              inputId="aria-example-input-class"
              options={[]}
              onChange={handleOrderChange}
              onCreateOption={addOrder}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={2}>
          {appContext.classesData.length > 0 &&
          appContext.classesData[selectedClassIndex].orders.length > 0 ? (
            <div>
              <Row>
                <Button
                  variant="danger"
                  onClick={() => {
                    deleteOrder();
                  }}
                >
                  Delete Order
                </Button>
              </Row>
              <Row>
                <Button
                  variant="success"
                  onClick={() => {
                    generateOrder();
                  }}
                >
                  Generate Order
                </Button>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {order}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
    </Body>
  );
}
