import Body from '../components/Body';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useState, useContext, useRef, useEffect } from 'react';
import AppContext from '../components/AppContext';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragStudent from '../components/DragStudent';
import DropDesk from '../components/DropDesk';
import DropUnseatedArea from '../components/DropUnseatedArea';
import { SelectTheme, SelectLabel, Scales } from '../components/Constants';

export default function ChartsPage() {
  const [selectedLayoutIndex, setSelectedLayoutIndex] = useState(0);
  const [selectedClassIndex, setSelectedClassIndex] = useState(0);
  const [selectedChartIndex, setSelectedChartIndex] = useState(0);
  const appContext = useContext(AppContext);

  const dragBoxRef = useRef(null);
  const layoutBoxRef = useRef(null);

  const fixIssuesWithCharts = () => {
    for (let i = 0; i < appContext.chartsData.length; i++) {
      let classFound = false;
      let layoutFound = false;

      for (let j = 0; j < appContext.classesData.length; j++) {
        if (appContext.chartsData[i].class_sub_id === appContext.classesData[j].sub_id) {
          classFound = true;
        }
      }

      for (let j = 0; j < appContext.layoutsData.length; j++) {
        if (appContext.chartsData[i].layout_sub_id === appContext.layoutsData[j].sub_id) {
          layoutFound = true;
        }
      }

      if (!classFound || !layoutFound) {
        const newChartsData = appContext.chartsData.filter(
          (el) => el.sub_id !== appContext.chartsData[i].sub_id
        );
        appContext.updateChartsData(newChartsData);
      }
    }
  };

  useEffect(() => {
    fixIssuesWithCharts();
    if (areAnyChartsValid(0, 0)) {
      setSelectedChartIndex(getFirstValidChartIndex(0, 0));
    } else {
      setSelectedChartIndex(0);
    }
  }, []);

  const areAnyChartsValid = (classIndex, layoutIndex) => {
    for (let i = 0; i < appContext.chartsData.length; i++) {
      if (
        appContext.classesData.length > selectedClassIndex &&
        appContext.layoutsData.length > selectedLayoutIndex &&
        appContext.chartsData[i].class_sub_id === appContext.classesData[classIndex].sub_id &&
        appContext.chartsData[i].layout_sub_id === appContext.layoutsData[layoutIndex].sub_id
      ) {
        return true;
      }
    }
    return false;
  };

  const getFirstValidChartIndex = (classIndex, layoutIndex) => {
    for (let i = 0; i < appContext.chartsData.length; i++) {
      if (
        appContext.chartsData[i].class_sub_id === appContext.classesData[classIndex].sub_id &&
        appContext.chartsData[i].layout_sub_id === appContext.layoutsData[layoutIndex].sub_id
      ) {
        return i;
      }
    }
    return null;
  };

  const handleLayoutChange = (selectedOption) => {
    for (let i = 0; i < appContext.layoutsData.length; i++) {
      if (selectedOption.sub_id === appContext.layoutsData[i].sub_id) {
        setSelectedLayoutIndex(i);
        if (areAnyChartsValid(selectedClassIndex, i) === true) {
          setSelectedChartIndex(getFirstValidChartIndex(selectedClassIndex, i));
        } else {
          setSelectedChartIndex(0);
        }
      }
    }
  };

  const handleClassChange = (selectedOption) => {
    for (let i = 0; i < appContext.classesData.length; i++) {
      if (selectedOption.sub_id === appContext.classesData[i].sub_id) {
        setSelectedClassIndex(i);
        if (areAnyChartsValid(i, selectedLayoutIndex)) {
          setSelectedChartIndex(getFirstValidChartIndex(i, selectedLayoutIndex));
        } else {
          setSelectedChartIndex(0);
        }
      }
    }
  };

  let unseatedStudents = null;
  if (appContext.classesData.length > selectedClassIndex) {
    unseatedStudents = appContext.classesData[selectedClassIndex].students.map((s, i) => {
      let isSeated = false;

      if (
        appContext.layoutsData.length > selectedLayoutIndex &&
        appContext.chartsData.length > selectedChartIndex &&
        areAnyChartsValid(selectedClassIndex, selectedLayoutIndex) &&
        appContext.chartsData[selectedChartIndex].student_ids.includes(s.id)
      ) {
        isSeated = true;
      }
      return isSeated ? null : (
        <DragStudent
          student={s}
          isSeated={isSeated}
          xPosition={0}
          yPosition={0}
          studentIndex={i}
          key={'student_' + i.toString() + '_' + s.name}
        />
      );
    });
  }

  const addChart = (name) => {
    const newChartData = {
      value: name,
      label: name,
      sub_id: appContext.userData.next_chart_sub_id,
      name,
      class_sub_id: appContext.classesData[selectedClassIndex].sub_id,
      layout_sub_id: appContext.layoutsData[selectedLayoutIndex].sub_id,
      seat_ids: [],
      student_ids: []
    };
    const newChartsData = appContext.chartsData.concat(newChartData);
    setSelectedChartIndex(newChartsData.length - 1);
    appContext.updateChartsData(newChartsData);

    const newUserData = { ...appContext.userData };
    newUserData.next_chart_sub_id = appContext.userData.next_chart_sub_id + 1;
    appContext.updateUserData(newUserData);
  };

  const deleteChart = () => {
    const newChartsData = appContext.chartsData.filter(
      (el) => el.sub_id !== appContext.chartsData[selectedChartIndex].sub_id
    );
    appContext.updateChartsData(newChartsData);
    setSelectedChartIndex(0);
  };

  const handleChartChange = (selectedOption) => {
    for (let i = 0; i < appContext.chartsData.length; i++) {
      if (selectedOption.sub_id === appContext.chartsData[i].sub_id) {
        setSelectedChartIndex(i);
        for (let j = 0; j < appContext.classesData.length; j++) {
          if (appContext.classesData[j].sub_id === selectedOption.class_sub_id) {
            setSelectedClassIndex(j);
          }
        }
        for (let j = 0; j < appContext.layoutsData.length; j++) {
          if (appContext.layoutsData[j].sub_id === selectedOption.layout_sub_id) {
            setSelectedLayoutIndex(j);
          }
        }
      }
    }
  };

  let layout = null;
  if (appContext.layoutsData.length > selectedLayoutIndex) {
    layout = appContext.layoutsData[selectedLayoutIndex].seats.map((s, i) => {
      let isEnabled = false;
      let dragStudent = null;
      if (
        appContext.classesData.length > selectedClassIndex &&
        appContext.chartsData.length > selectedChartIndex &&
        areAnyChartsValid(selectedClassIndex, selectedLayoutIndex)
      ) {
        isEnabled = true;

        if (appContext.chartsData[selectedChartIndex].seat_ids.includes(s.id)) {
          // If we are here, that means this seat is part of the chart... now check for a corresponding student
          const seatIndex = appContext.chartsData[selectedChartIndex].seat_ids.indexOf(s.id);
          if (
            appContext.chartsData[selectedChartIndex].student_ids.length > seatIndex &&
            appContext.chartsData[selectedChartIndex].student_ids[seatIndex] !== -1
          ) {
            const studentId = appContext.chartsData[selectedChartIndex].student_ids[seatIndex];
            for (let j = 0; j < appContext.classesData[selectedClassIndex].students.length; j++) {
              if (appContext.classesData[selectedClassIndex].students[j].id === studentId) {
                dragStudent = (
                  <DragStudent
                    student={appContext.classesData[selectedClassIndex].students[j]}
                    isSeated={true}
                    xPosition={Scales[appContext.layoutsData[selectedLayoutIndex].scale] / 10}
                    yPosition={Scales[appContext.layoutsData[selectedLayoutIndex].scale] - 10}
                    studentIndex={j}
                    key={
                      'student_' +
                      j.toString() +
                      '_' +
                      appContext.classesData[selectedClassIndex].students[j].name
                    }
                  />
                );
              }
            }
          }
        }
      }

      return (
        <DropDesk
          seat={s}
          index={i}
          scale={appContext.layoutsData[selectedLayoutIndex].scale}
          key={'seat_' + i.toString() + '_' + s.x + '_' + s.y}
          selectedClassIndex={selectedClassIndex}
          selectedLayoutIndex={selectedLayoutIndex}
          selectedChartIndex={selectedChartIndex}
          isEnabled={isEnabled}
        >
          {dragStudent}
        </DropDesk>
      );
    });
  }

  const generateChart = () => {
    // TODO: Add check for unequal number of seats and students and notify user of failure
    if (
      appContext.classesData.length > selectedClassIndex &&
      appContext.layoutsData.length > selectedLayoutIndex &&
      appContext.chartsData.length > selectedChartIndex &&
      areAnyChartsValid(selectedClassIndex, selectedLayoutIndex)
    ) {
      fetch('/api/charts/generate', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ' + appContext.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          students: appContext.classesData[selectedClassIndex].students,
          seats: appContext.layoutsData[selectedLayoutIndex].seats,
          scale: appContext.layoutsData[selectedLayoutIndex].scale,
          inverted: appContext.layoutsData[selectedLayoutIndex].inverted
        })
      })
        .then((response) => {
          if (response.status === 401) appContext.reset();
          else if (!response.ok) throw new Error(response.status);
          else return response.json();
        })
        .then((data) => {
          const newChartsData = appContext.chartsData.map((currentChart, idx) => {
            if (selectedChartIndex === idx) {
              return {
                ...appContext.chartsData[selectedChartIndex],
                student_ids: data.student_ids,
                seat_ids: data.seat_ids
              };
            } else {
              return currentChart;
            }
          });
          appContext.updateChartsData(newChartsData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Body>
      <Row className="justify-content-center">
        <Col md={3}>
          <label style={SelectLabel} id="aria-label-class" htmlFor="aria-example-input-class">
            Select a class
          </label>
          {appContext.classesData.length > 0 ? (
            <Select
              aria-labelledby="aria-label-class"
              inputId="aria-example-input-class"
              options={appContext.classesData}
              onChange={handleClassChange}
              value={appContext.classesData[selectedClassIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Select
              aria-labelledby="aria-label-class"
              inputId="aria-example-input-class"
              options={appContext.classesData}
              onChange={handleClassChange}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={3}>
          <label style={SelectLabel} id="aria-label-layout" htmlFor="aria-example-input-layout">
            Select a layout
          </label>
          {appContext.layoutsData.length > 0 ? (
            <Select
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.layoutsData}
              onChange={handleLayoutChange}
              value={appContext.layoutsData[selectedLayoutIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Select
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.layoutsData}
              onChange={handleLayoutChange}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={4}>
          <label style={SelectLabel} id="aria-label-layout" htmlFor="aria-example-input-layout">
            Select a seating chart or type a name to create a new one
          </label>
          {areAnyChartsValid(selectedClassIndex, selectedLayoutIndex) ? (
            <Creatable
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.chartsData}
              onChange={handleChartChange}
              onCreateOption={addChart}
              value={appContext.chartsData[selectedChartIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Creatable
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.chartsData}
              onChange={handleChartChange}
              onCreateOption={addChart}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={2}>
          {appContext.chartsData.length > 0 ? (
            <div>
              <Row>
                <Button variant="danger" onClick={() => deleteChart()}>
                  Delete Chart
                </Button>
              </Row>
              <Row>
                <Button
                  variant="success"
                  onClick={() => {
                    generateChart();
                  }}
                >
                  Generate Chart
                </Button>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <div className="dragBox elements selecto-area" id="dragBox" ref={dragBoxRef}>
        <DndProvider backend={HTML5Backend}>
          <div className="layoutBox" id="layoutBox" ref={layoutBoxRef}>
            {layout}
          </div>
          <DropUnseatedArea
            selectedClassIndex={selectedClassIndex}
            selectedLayoutIndex={selectedLayoutIndex}
            selectedChartIndex={selectedChartIndex}
            isEnabled={
              appContext.classesData.length > selectedClassIndex &&
              appContext.layoutsData.length > selectedLayoutIndex &&
              appContext.chartsData.length > selectedChartIndex &&
              areAnyChartsValid(selectedClassIndex, selectedLayoutIndex)
            }
          >
            <Container fluid></Container>
            {unseatedStudents}
          </DropUnseatedArea>
        </DndProvider>
      </div>
    </Body>
  );
}

// TODO long-term:
// - Auto-populate students onto layout based on criteria
