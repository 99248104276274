import { Colors } from './Constants.js';

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function pixelsToGrid(pixels, radius) {
  return (pixels - 1) / (radius / 5);
}

export function gridToPixels(grid, radius) {
  return grid * (radius / 5) + 1;
}

// Pass in an integer, 1-5, and get back the proper color to represent that performance level
export function getColorFromPerformanceLevel(level) {
  switch (level) {
    case 0:
      return Colors.danger;
    case 1:
      return Colors.warning;
    case 2:
      return Colors.dark;
    case 3:
      return Colors.info;
    case 4:
      return Colors.success;
    default:
      return Colors.dark;
  }
}
