import Body from '../components/Body';
import SignInWithGoogle from '../components/SignInWithGoogle';
import SignInForm from '../components/SignInForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import AppContext from '../components/AppContext';
import { useNavigate } from 'react-router-dom';

export default function SignInPage() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Body>
      <Row className="justify-content-center pt-4">
        <Col xs="auto">
          <SignInWithGoogle />
        </Col>
      </Row>
      <Row className="justify-content-center pt-4">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={4}>
          <SignInForm />
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
      <Row className="justify-content-center pt-3">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={4}>
          <Button variant="outline-danger" onClick={() => navigate('/reset-password-request')}>
            Forgot password?
          </Button>
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
      {appContext.signInFailed ? (
        <Row className="justify-content-center pt-3">
          <Col xs={1} md={3} lg={4}></Col>
          <Col xs={10} md={6} lg={4}>
            <Alert variant="danger" onClose={() => appContext.setSignInFailed(false)} dismissible>
              <Alert.Heading>Sign in attempt failed!</Alert.Heading>
              <p>The email or password provided were incorrect. Please try again!</p>
            </Alert>
          </Col>
          <Col xs={1} md={3} lg={4}></Col>
        </Row>
      ) : (
        <div />
      )}
    </Body>
  );
}
