import { useDrop } from 'react-dnd';
import { Colors, ItemTypes } from './Constants.js';
import { useContext } from 'react';
import AppContext from './AppContext.js';

export default function DropUnseatedArea({
  children,
  selectedClassIndex,
  selectedChartIndex,
  isEnabled
}) {
  const appContext = useContext(AppContext);
  const handleDrop = (dragIndex) => {
    var studentId = appContext.classesData[selectedClassIndex].students[dragIndex].id;

    if (appContext.chartsData[selectedChartIndex].student_ids.includes(studentId)) {
      // This student is seated currently, so remove them from the list
      const newChartsData = appContext.chartsData.map((currentChart, idx) => {
        if (idx === selectedChartIndex) {
          const newStudentIdsData = currentChart.student_ids.map((currentStudentId, idy) => {
            if (currentStudentId === studentId) {
              return -1;
            } else {
              return currentStudentId;
            }
          });
          return {
            ...currentChart,
            student_ids: newStudentIdsData
          };
        } else {
          return currentChart;
        }
      });
      appContext.updateChartsData(newChartsData);
    }
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.STUDENT_BADGE,
      canDrop: () => {
        return isEnabled;
      },
      drop: (item) => handleDrop(item.studentIndex),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [appContext]
  );

  return (
    <div
      ref={drop}
      draggable="false"
      key={'unseatedArea'}
      style={{
        backgroundColor: isOver && isEnabled ? Colors.primary : 'transparent',
        overflow: 'hidden'
      }}
      className="studentsBox"
    >
      {children}
    </div>
  );
}
