export const ItemTypes = {
  STUDENT_BADGE: 'student-badge'
};

export const Colors = {
  primary: `#78c2adFF`,
  primary25: `#78c2ad40`,
  light: `#f8f9fa`,
  dark: `#343a40`,
  success: `#56cc9d`,
  info: `#6cc3d5`,
  warning: `#ffce67`,
  danger: `#ff7851`
};

export const SelectLabel = {
  fontSize: '.90rem',
  fontWeight: 'bold',
  lineHeight: 2
};

export const SelectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: Colors.primary25,
    primary: Colors.primary
  }
});

export const Scales = [20, 25, 40, 50];
