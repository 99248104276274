import Body from '../components/Body';
import { ListGroup, Row, Col, Tab, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import AddStudentForm from '../components/AddStudentForm';
import { useRef, useContext, useState } from 'react';
import AppContext from '../components/AppContext';
import { SelectTheme, SelectLabel } from '../components/Constants';
import Creatable from 'react-select/creatable';

export default function ClassesPage() {
  const appContext = useContext(AppContext);
  const refs = useRef();
  refs.current = [];

  const [selectedClassIndex, setSelectedClassIndex] = useState(0);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const scrollToBottom = async () => {
    refs.current[0]?.scrollIntoView({ behavior: 'smooth' });
  };

  const addClassFunction = (name) => {
    const newClassesData = appContext.classesData.concat({
      value: name,
      label: name,
      sub_id: appContext.userData.next_class_sub_id,
      name,
      students: [],
      orders: [],
      next_order_id: 0,
      next_student_id: 0
    });
    setSelectedClassIndex(appContext.classesData.length);
    appContext.updateClassesData(newClassesData);

    var newUserData = { ...appContext.userData };
    newUserData.next_class_sub_id = appContext.userData.next_class_sub_id + 1;
    appContext.updateUserData(newUserData);
  };

  const deleteClassFunction = () => {
    const newClassesData = appContext.classesData.filter(
      (el) => el.sub_id !== appContext.classesData[selectedClassIndex].sub_id
    );
    setSelectedClassIndex(0);
    appContext.updateClassesData(newClassesData);
  };

  const handleClassChange = (selectedOption) => {
    for (let i = 0; i < appContext.classesData.length; i++) {
      if (selectedOption.sub_id === appContext.classesData[i].sub_id) {
        setSelectedClassIndex(i);
      }
    }
  };

  const addStudentFunction = async (name) => {
    const newClassesData = appContext.classesData.map((currentClass, idx) => {
      if (selectedClassIndex === idx) {
        const thisStudentId = currentClass.next_student_id;
        return {
          ...appContext.classesData[selectedClassIndex],
          next_student_id: thisStudentId + 1,
          students: [
            ...appContext.classesData[selectedClassIndex].students,
            { id: thisStudentId, name, behavior: 2, performance: 2, location: 1 }
          ]
        };
      } else {
        return currentClass;
      }
    });
    appContext.updateClassesData(newClassesData);
    setTimeout(() => {
      scrollToBottom(selectedClassIndex);
    }, 50);
  };

  const deleteStudentFunction = async (studentid) => {
    const newClassesData = appContext.classesData.map((currentClass, idx) => {
      if (selectedClassIndex === idx) {
        const newStudentsData = currentClass.students.filter(
          (el) => el.id !== appContext.classesData[selectedClassIndex].students[studentid].id
        );
        return {
          ...currentClass,
          students: newStudentsData
        };
      } else {
        return currentClass;
      }
    });
    appContext.updateClassesData(newClassesData);
  };

  const setBehaviorFunction = async (behavior, studentid) => {
    var student = appContext.classesData[selectedClassIndex].students[studentid];
    student.behavior = behavior;
    editStudentFunction(student, studentid);
  };

  const setPerformanceFunction = async (performance, studentid) => {
    var student = appContext.classesData[selectedClassIndex].students[studentid];
    student.performance = performance;
    editStudentFunction(student, studentid);
  };

  const setLocationFunction = async (location, studentid) => {
    var student = appContext.classesData[selectedClassIndex].students[studentid];
    student.location = location;
    editStudentFunction(student, studentid);
  };

  const editStudentFunction = async (student, studentid) => {
    const newClassesData = appContext.classesData.map((currentClass, idx) => {
      if (selectedClassIndex === idx) {
        const newStudentsData = currentClass.students.map((currentStudent, idy) => {
          if (studentid === idy) {
            return student;
          } else {
            return currentStudent;
          }
        });
        return {
          ...currentClass,
          students: newStudentsData
        };
      } else {
        return currentClass;
      }
    });
    appContext.updateClassesData(newClassesData);
  };

  var studentList = null;
  var studentInfo = null;
  let numStudents = 0;
  if (appContext.classesData.length > selectedClassIndex) {
    numStudents = appContext.classesData[selectedClassIndex].students.length;
    studentList = appContext.classesData[selectedClassIndex].students.map((s, i) => {
      return (
        <ListGroup.Item action href={'#link_' + i.toString()} key={i.toString() + '_name'}>
          {s.name}
        </ListGroup.Item>
      );
    });

    studentInfo = appContext.classesData[selectedClassIndex].students.map((s, i) => {
      const behaviorButtons = [
        { name: '1', value: 0 },
        { name: '2', value: 1 },
        { name: '3', value: 2 },
        { name: '4', value: 3 },
        { name: '5', value: 4 }
      ];
      const performanceButtons = [
        { name: '1', value: 0 },
        { name: '2', value: 1 },
        { name: '3', value: 2 },
        { name: '4', value: 3 },
        { name: '5', value: 4 }
      ];
      const locationButtons = [
        { name: 'Back', value: 0 },
        { name: 'Anywhere', value: 1 },
        { name: 'Front', value: 2 }
      ];
      return (
        <Tab.Pane eventKey={'#link_' + i.toString()} key={i.toString() + '_info'}>
          <h2> {s.name} </h2>
          <hr />
          <Row>
            <Col md={3} />
            <Col md={2}>
              <p>Poor</p>
            </Col>
            <Col md={2}>
              <p align="center">Average</p>
            </Col>
            <Col md={2}>
              <p align="right">Excellent</p>
            </Col>
            <Col md={3} />
          </Row>
          <Row>
            <Col md={3}>
              <Row className="pt-2">
                <p align="right">Behavior</p>
              </Row>
              <Row className="pt-2">
                <p align="right">Performance</p>
              </Row>
              <Row className="pt-2">
                <p align="right">Location</p>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="pt-2">
                <ToggleButtonGroup
                  type="radio"
                  value={s.behavior}
                  name={i.toString() + '_behavior_options'}
                  id={i.toString() + '_behavior_buttons_id'}
                  key={i.toString() + '_behavior_buttons_key'}
                  onChange={(val) => setBehaviorFunction(val, i)}
                >
                  {behaviorButtons.map((radio, idx) => (
                    <ToggleButton
                      id={i.toString() + '_' + idx.toString() + '_behavior_id'}
                      key={i.toString() + '_' + idx.toString() + '_behavior_key'}
                      value={radio.value}
                      variant="outline-primary"
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Row>
              <Row className="pt-2">
                <ToggleButtonGroup
                  type="radio"
                  value={s.performance}
                  name={i.toString() + '_performance_options'}
                  id={i.toString() + '_performance_buttons_id'}
                  key={i.toString() + '_performance_buttons_key'}
                  onChange={(val) => setPerformanceFunction(val, i)}
                >
                  {performanceButtons.map((radio, idx) => (
                    <ToggleButton
                      id={i.toString() + '_' + idx.toString() + '_performance_id'}
                      key={i.toString() + '_' + idx.toString() + '_performance_key'}
                      value={radio.value}
                      variant="outline-primary"
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Row>
              <Row className="pt-2">
                <ToggleButtonGroup
                  type="radio"
                  value={s.location}
                  name={i.toString() + '_location_options'}
                  id={i.toString() + '_location_buttons_id'}
                  key={i.toString() + '_location_buttons_key'}
                  onChange={(val) => setLocationFunction(val, i)}
                >
                  {locationButtons.map((radio, idx) => (
                    <ToggleButton
                      id={i.toString() + '_' + idx.toString() + '_location_id'}
                      key={i.toString() + '_' + idx.toString() + '_location_key'}
                      value={radio.value}
                      variant="outline-primary"
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Row>
            </Col>
            <Col md={3} />
          </Row>
          <hr />
          <Button variant="outline-danger" onClick={() => deleteStudentFunction(i)}>
            Delete Student
          </Button>
        </Tab.Pane>
      );
    });
  }

  return (
    <Body>
      <Row className="justify-content-center">
        <Col md={10}>
          <label style={SelectLabel}>Select a class or type a name to create a new one</label>
          {appContext.classesData.length > 0 ? (
            <Creatable
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.classesData}
              onChange={handleClassChange}
              onCreateOption={addClassFunction}
              value={appContext.classesData[selectedClassIndex]}
              isDisabled={false}
              theme={SelectTheme}
            />
          ) : (
            <Creatable
              aria-labelledby="aria-label-layout"
              inputId="aria-example-input-layout"
              options={appContext.classesData}
              onChange={handleClassChange}
              onCreateOption={addClassFunction}
              value={null}
              isDisabled={false}
              theme={SelectTheme}
            />
          )}
          <hr />
        </Col>
        <Col md={2}>
          {appContext.classesData.length > 0 ? (
            <div>
              <Row className="pt-4">
                <Button variant="danger" onClick={() => deleteClassFunction()}>
                  Delete Class
                </Button>
              </Row>
              <Row className="justify-content-center pt-2">
                <p>{'Number of students: ' + numStudents}</p>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      {appContext.classesData.length > 0 ? (
        <Tab.Container id="list-group-tabs-example">
          <Row>
            <Col md={4}>
              <Row>
                <ListGroup>
                  {studentList}
                  <div ref={addToRefs} />
                </ListGroup>
              </Row>
              <Row className="pt-2">
                <AddStudentForm addStudentFunction={addStudentFunction} />
              </Row>
            </Col>
            <Col md={8}>
              <Tab.Content>{studentInfo}</Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      ) : (
        <div />
      )}
    </Body>
  );
}
