import Body from '../components/Body';
import ResetPasswordRequestForm from '../components/ResetPasswordRequestForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import AppContext from '../components/AppContext';
import { useContext } from 'react';

export default function ResetPasswordRequestPage() {
  const appContext = useContext(AppContext);

  return (
    <Body>
      <Row className="justify-content-center pt-4">{"Let's reset your password!"}</Row>
      <Row className="justify-content-center pt-4">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={3}>
          <ResetPasswordRequestForm />
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
      {appContext.passwordResetFailed ? (
        <Row className="justify-content-center pt-4">
          <Col xs={1} md={3} lg={4}></Col>
          <Col xs={10} md={6} lg={4}>
            <Alert
              variant="danger"
              onClose={() => appContext.setPasswordResetFailed(false)}
              dismissible
            >
              <Alert.Heading>Password reset failed!</Alert.Heading>
              <p>
                The request to reset your password failed (the link sent to you previously may have
                expired). Please try again!
              </p>
            </Alert>
          </Col>
          <Col xs={1} md={3} lg={4}></Col>
        </Row>
      ) : (
        <div />
      )}
    </Body>
  );
}
