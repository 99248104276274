import Body from '../components/Body';
import SignInWithGoogle from '../components/SignInWithGoogle';
import SignUpForm from '../components/SignUpForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useContext, useEffect } from 'react';
import AppContext from '../components/AppContext';

export default function SignInPage() {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setSignInFailed(false);
    appContext.setPasswordResetFailed(false);
    appContext.setAuthenticatedPasswordResetFailed(false);
  }, []);

  return (
    <Body>
      <Row className="justify-content-center pt-4">
        <Col xs="auto">
          <SignInWithGoogle />
        </Col>
      </Row>
      <Row className="justify-content-center pt-4">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={4}>
          <SignUpForm />
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
      {appContext.signUpFailed ? (
        <Row className="justify-content-center pt-4">
          <Col xs={1} md={3} lg={4}></Col>
          <Col xs={10} md={6} lg={4}>
            <Alert variant="danger" onClose={() => appContext.setSignUpFailed(false)} dismissible>
              <Alert.Heading>Sign up attempt failed!</Alert.Heading>
              <p>{appContext.signUpFailedString}</p>
            </Alert>
          </Col>
          <Col xs={1} md={3} lg={4}></Col>
        </Row>
      ) : (
        <div />
      )}
    </Body>
  );
}
