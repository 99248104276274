import { useDrop } from 'react-dnd';
import { ItemTypes, Scales } from './Constants.js';
import { useContext } from 'react';
import AppContext from '../components/AppContext';
import { gridToPixels, getColorFromPerformanceLevel } from '../components/Utilities';

export default function DropDesk({
  children,
  seat,
  index,
  scale,
  selectedClassIndex,
  selectedLayoutIndex,
  selectedChartIndex,
  isEnabled
}) {
  const appContext = useContext(AppContext);
  const handleDrop = (dropIndex, dragIndex) => {
    var seatId = appContext.layoutsData[selectedLayoutIndex].seats[dropIndex].id;
    var studentId = appContext.classesData[selectedClassIndex].students[dragIndex].id;

    if (!appContext.chartsData[selectedChartIndex].seat_ids.includes(seatId)) {
      // This seat/student pair needs to be added to the list
      const newChartsData = appContext.chartsData.map((currentChart, idx) => {
        // Check if this student was previously in a different spot
        const newStudentIdsData = currentChart.student_ids.map((currentStudentId, idy) => {
          if (currentStudentId === studentId) {
            return -1; // If the student is coming from another desk, set that desk to empty
          } else {
            return currentStudentId;
          }
        });
        if (idx === selectedChartIndex) {
          return {
            ...currentChart,
            seat_ids: [...currentChart.seat_ids, seatId],
            student_ids: [...newStudentIdsData, studentId]
          };
        } else {
          return currentChart;
        }
      });
      appContext.updateChartsData(newChartsData);
    } else {
      // This seat is already in the list so just move this student to it
      const newChartsData = appContext.chartsData.map((currentChart, idx) => {
        if (idx === selectedChartIndex) {
          const newStudentIdsData = currentChart.student_ids.map((currentStudentId, idy) => {
            if (idy === appContext.chartsData[selectedChartIndex].seat_ids.indexOf(seatId)) {
              return studentId;
            } else if (currentStudentId === studentId) {
              return -1; // If the student came from another desk, set that desk to empty
            } else {
              return currentStudentId;
            }
          });
          return {
            ...currentChart,
            student_ids: newStudentIdsData
          };
        } else {
          return currentChart;
        }
      });
      appContext.updateChartsData(newChartsData);
    }
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.STUDENT_BADGE,
      canDrop: () => {
        return isEnabled;
      },
      drop: (item) => handleDrop(index, item.studentIndex),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [appContext]
  );

  var left = gridToPixels(seat.x, Scales[appContext.layoutsData[selectedLayoutIndex].scale]) + 'px';
  var top = gridToPixels(seat.y, Scales[appContext.layoutsData[selectedLayoutIndex].scale]) + 'px';
  const radius = Scales[scale];
  const color = getColorFromPerformanceLevel(seat.performance);
  return (
    <div
      ref={drop}
      className="SeatingChartDesk"
      key={'seat_' + index.toString()}
      draggable="false"
      style={{
        left,
        top,
        position: 'absolute',
        overflow: 'hidden'
      }}
    >
      <svg
        draggable="false"
        xmlns="http://www.w3.org/2000/svg"
        width={(radius * 2).toString()}
        height={(radius * 2).toString()}
      >
        <circle
          cx={radius.toString()}
          cy={radius.toString()}
          r={(radius - radius / 20).toString()}
          stroke={color}
          strokeWidth={(radius / 10).toString()}
          fill={color}
          fillOpacity={isOver && isEnabled ? '1.0' : '0.25'}
        />
      </svg>
      {children}
    </div>
  );
}
