import Body from '../components/Body';
import ResetPasswordForm from '../components/ResetPasswordForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams } from 'react-router-dom';

export default function ResetPasswordPage({ isLoggedIn = false }) {
  const searchParams = useSearchParams();
  let token = '';
  let email = '';

  if (!isLoggedIn) {
    token = searchParams[0].get('token');
    email = searchParams[0].get('email');
  }

  return (
    <Body>
      <Row className="justify-content-center pt-4">
        <Col xs={1} md={3} lg={4}></Col>
        <Col xs={10} md={6} lg={3}>
          <ResetPasswordForm isLoggedIn={isLoggedIn} email={email} token={token} />
        </Col>
        <Col xs={1} md={3} lg={4}></Col>
      </Row>
    </Body>
  );
}
