import { Formik, Field, Form } from 'formik';
import AppContext from '../components/AppContext';
import * as Yup from 'yup';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SignInForm() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={(values, { setSubmitting }) => {
        appContext.setSignInFailed(false);
        setTimeout(() => {
          fetch('/api/auth/login', {
            headers: {
              Authorization: 'Basic ' + btoa(`${values.email}:${values.password}`)
            }
          })
            .then((response) => {
              if (!response.ok) throw new Error(response.status);
              else return response.json();
            })
            .then((data) => {
              appContext.setToken(btoa(`${data.token.replace('token=', '')}:${''}`));
              navigate('/home');
            })
            .catch((error) => {
              console.log(error);
              appContext.setSignInFailed(true);
            });
          setSubmitting(false);
        }, 100);
      }}
      validationSchema={schema}
    >
      {(formik, isSubmitting) => (
        <Form>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <Field
              name="email"
              type="email"
              className={
                formik.touched.email && formik.errors.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group pt-2">
            <label htmlFor="password">Password</label>
            <Field
              name="password"
              type="password"
              className={
                formik.touched.password && formik.errors.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="form-group pt-3">
            <button type="submit" className="btn btn-outline-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Please wait...' : 'Sign In'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
