import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

export default function ResetPasswordRequestForm() {
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          fetch('/api/auth/reset_password_request', {
            method: 'POST',
            body: JSON.stringify({
              email: values.email,
              url: window.location.origin + '/reset-password'
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then((response) => {
              if (!response.ok) throw new Error(response.status);
              else return response.json();
            })
            .then((data) => {
              navigate('/home');
            })
            .catch((error) => {
              console.log(error);
            });
          setSubmitting(false);
        }, 100);
      }}
      validationSchema={schema}
    >
      {(formik, isSubmitting) => (
        <Form>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <Field
              name="email"
              type="email"
              className={
                formik.touched.email && formik.errors.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group pt-3">
            <button type="submit" className="btn btn-outline-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Please wait...' : 'Send password reset link'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
