// import { useState, useEffect, useContext, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './Constants';
import Badge from 'react-bootstrap/Badge';

export default function DragStudent({ student, studentIndex, isSeated, xPosition, yPosition }) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.STUDENT_BADGE,
      item: { studentIndex },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [isSeated, xPosition, yPosition]
  );

  var style = {};
  if (!isSeated) {
    style = { opacity: isDragging ? 0.5 : 1, cursor: 'move' };
  } else {
    var left = xPosition + 'px';
    var top = yPosition + 'px';
    style = {
      left,
      top,
      position: 'absolute',
      opacity: isDragging ? 0.5 : 1,
      cursor: 'move'
    };
  }

  return (
    <Badge pill ref={drag} className="SeatingChartStudent" style={style} bg="dark">
      {student.name}
    </Badge>
  );
}
