import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AppContext from './components/AppContext';
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import SignOutPage from './pages/SignOutPage';
import ResetPasswordRequestPage from './pages/ResetPasswordRequestPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ClassesPage from './pages/ClassesPage';
import OrdersPage from './pages/OrdersPage';
import LayoutsPage from './pages/LayoutsPage';
import ChartsPage from './pages/ChartsPage';
import UserPage from './pages/UserPage';
import Spinner from 'react-bootstrap/Spinner';
import { sleep } from './components/Utilities';

export default function App() {
  const navigate = useNavigate();

  // Login information
  const [token, setToken] = useState('');
  const [signInFailed, setSignInFailed] = useState(false);
  const [signUpFailed, setSignUpFailed] = useState(false);
  const [signUpFailedString, setSignUpFailedString] = useState('Error');
  const [passwordResetFailed, setPasswordResetFailed] = useState(false);
  const [authenticatedPasswordResetFailed, setAuthenticatedPasswordResetFailed] = useState(false);

  // Globally shared application state data
  const [userData, setUserData] = useState({});
  const [userSaveState, setUserSaveState] = useState('NONE');
  const [classesData, setClassesData] = useState([]);
  const [classesSaveState, setClassesSaveState] = useState('NONE');
  const [layoutsData, setLayoutsData] = useState([]);
  const [layoutsSaveState, setLayoutsSaveState] = useState('NONE');
  const [chartsData, setChartsData] = useState([]);
  const [chartsSaveState, setChartsSaveState] = useState('NONE');
  const [overallSaveState, setOverallSaveState] = useState('NONE');
  const [isBackendCommunicationEstablished, setIsBackendCommunicationEstablished] = useState(false);

  const reset = () => {
    setToken('');
    localStorage.removeItem('token');

    setSignInFailed(false);
    setSignUpFailed(false);
    setPasswordResetFailed(false);
    setAuthenticatedPasswordResetFailed(false);

    setUserSaveState('NONE');
    setUserData({});
    setClassesSaveState('NONE');
    setClassesData({});
    setLayoutsSaveState('NONE');
    setLayoutsData({});
    setChartsSaveState('NONE');
    setChartsData({});
    navigate('/home');
  };

  const updateUserData = (newUserData) => {
    setUserData(newUserData);
    setUserSaveState('UNSAVED');
    setOverallSaveState('UNSAVED');
  };

  const updateClassesData = (newClassesData) => {
    setClassesData(newClassesData);
    setClassesSaveState('UNSAVED');
    setOverallSaveState('UNSAVED');
  };

  const updateLayoutsData = (newLayoutsData) => {
    setLayoutsData(newLayoutsData);
    setLayoutsSaveState('UNSAVED');
    setOverallSaveState('UNSAVED');
  };

  const updateChartsData = (newChartsData) => {
    setChartsData(newChartsData);
    setChartsSaveState('UNSAVED');
    setOverallSaveState('UNSAVED');
  };

  const loadAllData = async () => {
    const response = await fetch('/api', {
      headers: {
        Authorization: 'Basic ' + appContext.token
      }
    });

    if (response.status === 401) reset();
    else if (!response.ok) throw new Error(response.status);
    else {
      const data = await response.json();
      // Add some extra things to each set of items for listing them in selectable lists
      data.classes.forEach((item) => {
        item.value = item.name;
        item.label = item.name;
      });
      data.layouts.forEach((item) => {
        item.value = item.name;
        item.label = item.name;
      });
      data.charts.forEach((item) => {
        item.value = item.name;
        item.label = item.name;
      });

      // Save retrieved data into global data structures
      setUserData(data.user);
      setUserSaveState('NONE');
      setClassesData(data.classes);
      setClassesSaveState('NONE');
      setLayoutsData(data.layouts);
      setLayoutsSaveState('NONE');
      setChartsData(data.charts);
      setChartsSaveState('NONE');
    }
  };

  const saveAllData = async () => {
    setOverallSaveState('SAVING');
    if (userSaveState === 'UNSAVED') {
      const response = await fetch('/api/user', {
        method: 'PUT',
        headers: {
          Authorization: 'Basic ' + appContext.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...userData
        })
      });

      if (response.status === 401) reset();
      else if (!response.ok) throw new Error(response.status);
      else {
        const data = await response.json();
        setUserData(data);
        setUserSaveState('SAVED');
      }
    }

    if (classesSaveState === 'UNSAVED') {
      const response = await fetch('/api/classes', {
        method: 'PUT',
        headers: {
          Authorization: 'Basic ' + appContext.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          classes: classesData
        })
      });

      if (response.status === 401) reset();
      else if (!response.ok) throw new Error(response.status);
      else {
        const data = await response.json();
        data.classes.forEach((item) => {
          item.value = item.name;
          item.label = item.name;
        });
        setClassesData(data.classes);
        setClassesSaveState('SAVED');
      }
    }

    if (layoutsSaveState === 'UNSAVED') {
      const response = await fetch('/api/layouts', {
        method: 'PUT',
        headers: {
          Authorization: 'Basic ' + appContext.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          layouts: layoutsData
        })
      });

      if (response.status === 401) reset();
      else if (!response.ok) throw new Error(response.status);
      else {
        const data = await response.json();
        data.layouts.forEach((item) => {
          item.value = item.name;
          item.label = item.name;
        });
        setLayoutsData(data.layouts);
        setLayoutsSaveState('SAVED');
      }
    }

    if (chartsSaveState === 'UNSAVED') {
      const response = await fetch('/api/charts', {
        method: 'PUT',
        headers: {
          Authorization: 'Basic ' + appContext.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          charts: chartsData
        })
      });

      if (response.status === 401) reset();
      else if (!response.ok) throw new Error(response.status);
      else {
        const data = await response.json();
        data.charts.forEach((item) => {
          item.value = item.name;
          item.label = item.name;
        });
        setChartsData(data.charts);
        setChartsSaveState('SAVED');
      }
    }
  };

  const appContext = {
    // Utilities
    reset,
    token,
    setToken,
    signInFailed,
    setSignInFailed,
    signUpFailed,
    setSignUpFailed,
    signUpFailedString,
    setSignUpFailedString,
    passwordResetFailed,
    setPasswordResetFailed,
    authenticatedPasswordResetFailed,
    setAuthenticatedPasswordResetFailed,

    // Local state
    userData,
    updateUserData,
    classesData,
    updateClassesData,
    layoutsData,
    updateLayoutsData,
    chartsData,
    updateChartsData,

    // Save state (NONE, UNSAVED, SAVING, SAVED)
    overallSaveState,

    // Save local state to backend
    saveAllData
  };

  const establishCommunication = async () => {
    let isCommunicationEstablished = false;
    while (!isCommunicationEstablished) {
      const response = await fetch('/api/time', {
        method: 'GET'
      });
      if (response.ok) isCommunicationEstablished = true;
      else await sleep(500);
    }
    setIsBackendCommunicationEstablished(true);
  };

  useEffect(() => {
    establishCommunication();
  }, []);

  useEffect(() => {
    // On startup, check for previously saved login token
    if (isBackendCommunicationEstablished) {
      const localToken = JSON.parse(localStorage.getItem('token'));
      if (localToken) {
        // Set token from locally saved token if present
        setToken(localToken);
      }
    }
  }, [isBackendCommunicationEstablished]);

  useEffect(() => {
    if (token !== '') {
      localStorage.setItem('token', JSON.stringify(token));

      // If token state changes then we need to reload all data
      loadAllData();

      setOverallSaveState('NONE');
    }
  }, [token]);

  const isAnyDataUnsaved =
    userSaveState === 'UNSAVED' ||
    classesSaveState === 'UNSAVED' ||
    layoutsSaveState === 'UNSAVED' ||
    chartsSaveState === 'UNSAVED';

  if (!isAnyDataUnsaved && overallSaveState === 'SAVING') {
    setOverallSaveState('SAVED');
  }

  return (
    <AppContext.Provider value={appContext}>
      <Container fluid className="App">
        {!isBackendCommunicationEstablished ? (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <Header />
            <Routes>
              {appContext.token && appContext.userData ? (
                <>
                  <Route path="/" element={<HomePage isLoggedIn={true} />} />
                  <Route path="/classes" element={<ClassesPage />} />
                  <Route path="/orders" element={<OrdersPage />} />
                  <Route path="/layouts" element={<LayoutsPage />} />
                  <Route path="/charts" element={<ChartsPage />} />
                  <Route path="/signout" element={<SignOutPage />} />
                  <Route path="/user" element={<UserPage />} />
                  <Route path="/reset-password" element={<ResetPasswordPage isLoggedIn={true} />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<HomePage isLoggedIn={false} />} />
                  <Route path="/signin" element={<SignInPage />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/reset-password-request" element={<ResetPasswordRequestPage />} />
                  <Route
                    path="/reset-password"
                    element={<ResetPasswordPage isLoggedIn={false} />}
                  />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        )}
      </Container>
    </AppContext.Provider>
  );
}
