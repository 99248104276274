import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AppContext from '../components/AppContext';

export default function SignInForm() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required')
  });

  return (
    <Formik
      initialValues={{ username: '', email: '', password: '', passwordConfirmation: '' }}
      onSubmit={(values, { setSubmitting }) => {
        appContext.setSignUpFailed(false);
        setTimeout(() => {
          fetch('/api/auth/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              username: values.username,
              email: values.email,
              password: values.password
            })
          })
            .then((response) => {
              if (response.status === 409) {
                const errorString = 'Email already in use, please try again';
                appContext.setSignUpFailedString(errorString);
                throw new Error(errorString);
              } else if (!response.ok) {
                appContext.setSignUpFailedString('Please try again');
                throw new Error(response.status);
              } else navigate('/signin');
            })
            .catch((error) => {
              console.log(error);
              appContext.setSignUpFailed(true);
            });
          setSubmitting(false);
        }, 100);
      }}
      validationSchema={schema}
    >
      {(formik, isSubmitting) => (
        <Form>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Field
              name="username"
              type="username"
              className={
                formik.touched.username && formik.errors.username
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="invalid-feedback">{formik.errors.username}</div>
            ) : null}
          </div>
          <div className="form-group pt-2">
            <label htmlFor="email">Email Address</label>
            <Field
              name="email"
              type="email"
              className={
                formik.touched.email && formik.errors.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group pt-2">
            <label htmlFor="password">Password</label>
            <Field
              name="password"
              type="password"
              className={
                formik.touched.password && formik.errors.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="form-group pt-2">
            <label htmlFor="passwordConfirmation">Password Confirmation</label>
            <Field
              name="passwordConfirmation"
              type="password"
              className={
                formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
              <div className="invalid-feedback">{formik.errors.passwordConfirmation}</div>
            ) : null}
          </div>
          <div className="form-group pt-3">
            <button type="submit" className="btn btn-outline-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Please wait...' : 'Sign Up'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
