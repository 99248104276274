import AppContext from '../components/AppContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

export default function SignInWithGoogle() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const handleGoogleSignIn = (googleData) => {
    fetch('/api/auth/google', {
      method: 'POST',
      body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        appContext.setToken(btoa(`${data.token.replace('token=', '')}:${''}`));
        navigate('/home');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Sign in with Google"
      onSuccess={handleGoogleSignIn}
      onFailure={handleGoogleSignIn}
      cookiePolicy={'single_host_origin'}
    />
  );
}
